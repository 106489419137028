import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/Hero'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const NotFoundPage = () => (
  <Layout hero={<Hero />} showMap={false}>
    <SEO title="404: Page Not Found" />
    <Container>
      <Row className="text-center py-5 my-5">
        <Col>
          <h1>{`Your Project {here}`}</h1>
          <p className="font-weight-bolder">404: Page Not Found</p>
          <p>You've reached a page that doesn't exist, but hey, <a href="/hire-us">anything is possible.</a></p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
